// src/VerticalBlocks.js
import React from 'react';

function VerticalBlocks(props) {
  return (
    <div className="container">
      <div className="block-left"><h2>{props.data.left}</h2></div>
      <div className="block-center"><h2>{props.data.center}</h2></div>
      <div className="block-right"><h2>{props.data.right}</h2></div>
    </div>
  );
}

export default VerticalBlocks;
