import { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import { Outlet } from "react-router-dom";
import Navbar from './Navbar';

const Layout = () => {
  const refreshInteval = 10;

  const [isRefreshing, setIsRefreshing] = useState(false);
  useEffect(() => {
    // Simulate a page refresh every 5 seconds
    const refreshInterval = setInterval(() => {
      setIsRefreshing(true);
      setTimeout(() => {
        setIsRefreshing(false);
      }, 2200); // Adjust the delay as needed
    }, refreshInteval * 1000); // Refresh every 5 seconds

    return () => clearInterval(refreshInterval);
  }, []);

  return (
    <>
      <Navbar />
      {isRefreshing && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={200} // Adjust the number of confetti pieces
          recycle={false}
          run={isRefreshing}
          confettiSource={{ x: window.innerWidth / 2, y: window.innerHeight / 2}}
          initialVelocityX={8}
          initialVelocityY={20}
        />
      )}
      {!isRefreshing && (
        <Outlet />
      )}
    </>
  )
};

export default Layout;