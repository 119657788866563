// src/components/Navbar.js
import React from 'react';
import NavItem from './NavItem';
import './Navbar.css'; 

function Navbar() {
  return (
    <nav className="navbar">
      <ul className="nav-list">
        <NavItem to="/" text="Words" />
        <NavItem to="/sentences" text="Sentences" />
      </ul>
    </nav>
  );
}

export default Navbar;
