import React from 'react';

function Footer(props) {
  return (
    <div className="container">
      <div className="block-footer"><h2>{props.data.text}</h2></div>
    </div>
  );
}

export default Footer;
