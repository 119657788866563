import React, { useEffect, useState } from 'react';
import HeroSection from './HeroSection';
import VerticalBlocks from './VerticalBlocks';

function Sentences() {
  const [item, setItem] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch('/data.json'); // Fetch the data.json file
        const response = await fetch("https://api.practiceit.net/sentences");
        const data = await response.json(); // Parse the JSON data
        const item = data.items[Math.floor(Math.random()*data.items.length)]
        // console.log(item);
        setItem(item); // Store the data in the state variable
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <HeroSection data={item} />
      <VerticalBlocks data={item} />
    </div>
  );
}

export default Sentences;

