// src/components/NavItem.js
import React from 'react';
import { Link } from 'react-router-dom';
import './NavItem.css';

function NavItem({ to, text }) {
  return (
    <li className="nav-item">
      <Link to={to} className="nav-item">{text}</Link>
    </li>
  );
}

export default NavItem;