// src/HeroSection.js
import React from 'react';

function HeroSection(props) {
  return (
    <div className="hero">
      <img src={props.data.image} alt="Hero" />
    </div>
  );
}

export default HeroSection;
